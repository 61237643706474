// Menu
const hamburguer = document.querySelector('.main-header__hamburguer');
const hamburguerBars = document.querySelector('.main-header__bars');
const mainHeader = document.querySelector('.main-header');

const modal = document.querySelector('.modal-search')
const modalOpen = document.querySelector('.main-header__search')
const modalClose = document.querySelector('.modal-search__close')

hamburguer.addEventListener('click', function () {
	hamburguerBars.classList.toggle('main-header__bars--active');
	mainHeader.classList.toggle('menu-active');
	// document.body.classList.toggle('stop-scroll')
})

// Submenu
const itemMenu = document.querySelectorAll('.main-header__has-child');

itemMenu.forEach(function (el) {
	el.addEventListener('click', function () {
		if (window.innerWidth <= 768) {
			console.log(window.innerWidth);
			el.classList.toggle('open');
		}
	})
})

// Bannres
$(document).ready(function () {
	$('.testimony .testimony__items').slick({
		prevArrow: $('.testimony__arrow-left'),
		nextArrow: $('.testimony__arrow-right'),
		slide: '.testimony__item',
		autoplay: true,
		mobileFirst: true,
		responsive: [
			{
				breakpoint: 767,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2
				}
			}
		]
	});

	$('.main-banner .main-banner__list').slick({
		prevArrow: $('.main-banner__arrow-left'),
		nextArrow: $('.main-banner__arrow-right'),
		slide: '.main-banner__item',
		autoplay: true,
	});

	$('.about__gallery').slick({
		prevArrow: $('.about__arrow-left'),
		nextArrow: $('.about__arrow-right'),
		slide: '.about__gallery-item',
		autoplay: true,
	});

	$('.certificate-of-bravery .certificate-of-bravery__items').slick({
		prevArrow: $('.certificate-of-bravery__arrow-left'),
		nextArrow: $('.certificate-of-bravery__arrow-right'),
		slide: '.certificate-of-bravery__item',
		autoplay: true,
		mobileFirst: true,
		responsive: [
			{
				breakpoint: 767,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 1023,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1
				}
			},
		]
	});
});

// faq
const faqItems = document.querySelectorAll('.faq .faq__item');

faqItems.forEach(el => {
	el.querySelector('.faq__header').addEventListener('click', () => {

		if (el.classList.contains('active')) {
			el.classList.remove('active');
		} else {
			faqItems.forEach(element => {
				element.classList.remove('active');
			})
			el.classList.add('active');
		}
	})
})

//Add class no submenu
document.querySelector('.menu-item-has-children').classList.add('main-header__has-child')

/*MODAL **********************************************/
modalOpen.onclick = () => {
    modal.style.display = "flex";
    toggleMenu()
}

modalClose.onclick = function() {
    modal.style.display = "none";
}

window.onclick = (event) => {
    if (event.target == modal) {
      modal.style.display = "none";
    }
}

const map_item = document.querySelector('#map');

if(map_item){
	const map = L.map('map').setView([-27.5, -48.58], 7);

	const tiles = L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
		maxZoom: 19,
		attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
	}).addTo(map);

	const circle = L.circle([-27.5, -48.58], {
		color: 'red',
		fillColor: '#f03',
		fillOpacity: 0.2,
		radius: 150000
	}).addTo(map);

}



